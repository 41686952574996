import React, {useState, Fragment} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";
import XImage from "./../images/x-bg.webp";
import COLORS from "./../styles/colors";
import TopImage from "./../images/3-pics.png";
import KidsImage from "./../images/IMG_2558.gif";
import TwitterImage from "./../images/twitter.png";
import axios from "axios";
import Lisa from "./../images/lisa.jpg";
import Leah from "./../images/leah.jpg";

const IntroContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    min-height: 500px;

    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
    }
`;

const IntroPanel = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IntroLeft = styled(IntroPanel)`
    background: linear-gradient(
      rgba(53, 110, 216, 0.8), 
      rgba(53, 110, 216, 0.8)
    ),
    url(${KidsImage});
    background-position: top center;
    background-size: cover;
    min-height: 300px;
    h1 {
        color: #fff;
        font-weight: 300;
        line-height: 2.5rem;
        font-size: 2.2rem;
    }
`;

const Header = styled.div`
    padding: 3rem 2rem;
    text-align: center;
    background: linear-gradient(
      rgba(53, 110, 216, 0.8), 
      rgba(53, 110, 216, 0.8)
    ),
    url(${TopImage});
    background-position: 0 10%;
    background-size: cover;
    h1 {
        line-height: 2.5rem;
        color: #fff;
    }
`;

const FormWrapper = styled.div`
    background: url(${XImage});
    padding: 3rem 1rem;
`;

const FormContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 3rem;
    background-color: #fff;
    box-shadow: 3px 4px 8px #aaa;
`;

const FormText = styled.div`
    margin-bottom: 2rem;
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const StyledInput = styled.input`
    display: block;
    margin-bottom: 1rem;
    width: calc(100% - 2rem);
    max-width: 500px;
    line-height: 1.5rem;
    padding: 0.5rem;
    font-weight: 300;
`;

const StyledTextarea = styled.textarea`
    display: block;
    margin-bottom: 1rem;
    width: calc(100% - 2rem);
    max-width: 500px;
    line-height: 1.5rem;
    padding: 0.5rem;
    font-weight: 300;
    border-color: #ccc;
`;

const Form = styled.form`
    button {
        border: 2px solid ${COLORS.primaryColor};
        padding: 0.5rem 1rem;
        margin-top: 0.5rem;
        text-decoration: none;
        color: ${COLORS.primaryColor};
        transition: all  0.5s;
        display: inline-block;

        &:hover {
            background-color: ${COLORS.primaryColor};
            color: #fff;
            cursor: pointer;
        }
    }
`;

const FormInner = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 6rem;

    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
`;

const StyledError = styled.div`
    padding-bottom: 0.5rem;
    color: red;
`;

const RequiredSpan = styled.span`
    display: inline-block;
    color: red;
`;

const UnderLineText = styled.a`
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
`;

const SocialMediaContainer = styled.div`
    padding: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    text-align: center;
    a {
        text-decoration: none;
        color: #222;
        &:hover {
            color: ${COLORS.primaryColor};
        }
    }

    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr;
        padding: 2rem 1rem;
        a {
            display: block;
            margin-top: 2rem;
        }
    }

    img {
        max-width: 3rem;
        display: block;
        margin: 0 auto;
    }

`;

const CheckboxContainers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    label {
        padding-right: 1rem;
        font-weight: 300;
    }

    span {
        display: inline-block;
        margin-right: 0.5rem;
        font-weight: 300;
    }
`;

const Checkbox = styled.input`
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 3rem;
    vertical-align: middle;
    background-color: ${COLORS.primaryColor};
    cursor:pointer;

    &:checked {
        background-color: ${COLORS.secondaryColor};
    }
`;

const PeopleContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    max-width: 1000px; 
    margin: 0 auto;

    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
    }
`;

const PeoplePanel = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    padding: 1rem;
    h3 {
        margin-bottom: 0;
    }

    img {
        max-width: 200px;
    }
`;


function testEmail(email) {
    const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    return emailRegex.test(email);
}

const ContactPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [preference, setPreference] = useState("");
    const [role, setRole] = useState("");
    const [schoolDistrict, setSchoolDistrict] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false)

    const submit = async (ev) => {
        ev.preventDefault();
        resetErrors();

        // Validation on the form
        if (!name) return setError("Missing Required Field: Name")
        if (!email) return setError("Missing Required Field: Email")
        if (!testEmail(email)) return setError("Invalid Email Format")
        if (!message) return setError("Missing Required Field: Message")

        let myForm = document.getElementById('MyForm');
        let formData = new FormData(myForm);
        // Preference is not set on the form automatically
        const formPreference = preference ? preference : "no preference";
        formData.append("preference", formPreference);

        axios({
            url: `https://getform.io/f/dec790ef-692d-467a-b1f6-4dfc6d193d5e`,
            method: "POST",
            data: formData
        }).then(() => {
            setSuccess(true);
        }).catch(error => {
            setError(error.message);
        });

    }

    const resetErrors = () => {setError("")}

    const FormDisplay = (
        <Fragment>
            <FormText>
                <h1>Getting in touch is easy.</h1>
                <p>Let us know how we can be of assistance.</p>
            </FormText>
            <Form id="MyForm">
                <FormInner>
                    <div>
                        <div>Name <RequiredSpan>*</RequiredSpan></div>
                        <StyledInput type="text" name="name" onChange={(ev) => {resetErrors(); setName(ev.target.value)}} value={name} />
                        <div>Email <RequiredSpan>*</RequiredSpan></div>
                        <StyledInput type="text" name="email" onChange={(ev) => {resetErrors(); setEmail(ev.target.value)}} value={email} />
                        <div>Phone</div>
                        <StyledInput type="text" name="phone" onChange={(ev) => {resetErrors(); setPhone(ev.target.value)}} value={phone} />
                        <div>Preferred Contact Method</div>
                        <CheckboxContainers>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <span>Email</span>
                                <Checkbox type="radio" checked={preference === "email"} onChange={() => {resetErrors(); setPreference("email")}} />
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <span>Phone</span>
                                <Checkbox type="radio" checked={preference === "phone"} onChange={() => {resetErrors(); setPreference("phone")}} />
                            </div>
                        </CheckboxContainers>
                    </div>
                    <div>
                        <div>Role</div>
                        <StyledInput type="text" name="role" onChange={(ev) => {resetErrors(); setRole(ev.target.value)}} value={role} />
                        <div>School District / Organization</div>
                        <StyledInput type="text" name="school-district" onChange={(ev) => {resetErrors(); setSchoolDistrict(ev.target.value)}} value={schoolDistrict} />
                        <div>Message <RequiredSpan>*</RequiredSpan></div>
                        <StyledTextarea rows="5" style={{resize: "none"}} name="message" onChange={(ev) => {resetErrors(); setMessage(ev.target.value)}} value={message} />
                    </div>
                </FormInner>
                {error ? <StyledError>{error}</StyledError> : ""}
                <button onClick={(ev) => submit(ev)}>Submit</button>
            </Form>
        </Fragment>
    );

    const SuccessDisplay = (
        <FormText>
            <h2>Thank You</h2>
            <p>We will be in contact with you shortly!</p>
        </FormText>
    );

    return (
        <Layout>
            <SEO title="Contact" />
            <IntroContainer>
                <IntroLeft>
                    <h1>Let us hear it!</h1>
                </IntroLeft>
                <IntroPanel>
                    <PeopleContainer>
                        <PeoplePanel>
                            <img src={Lisa} alt="lisa" />
                            <h3>Lisa Wellborn</h3>
                            <div>National Consultant</div>
                            <div>Mainline: 806.407.5354</div>
                            <div>Cell: 972.989.6341</div>
                        </PeoplePanel>
                        <PeoplePanel>
                            <img src={Leah} alt="leah" />
                            <h3>Leah Wetzler</h3>
                            <div>Southeast Regional Consultant</div>
                            <div>Mainline: 910.200.9810</div>
                        </PeoplePanel>
                    </PeopleContainer>
                </IntroPanel>
            </IntroContainer>
            <FormWrapper>
                <FormContainer>
                    {success ? SuccessDisplay : FormDisplay}
                </FormContainer>
            </FormWrapper>
            <div style={{textAlign: "center", padding: "1rem 0"}}>
                <h3>Email Us:</h3>
                <h2 style={{marginTop: 0}}><UnderLineText>info@s3strategies.net</UnderLineText></h2>
                <h3>Or Call:</h3>
                <h2 style={{marginTop: 0}}><UnderLineText href="tel:806-407-5354">806-407-5354</UnderLineText></h2>
            </div>
            <Header><h1>Follow us on Social Media</h1></Header>
            <SocialMediaContainer>
                <a href="https://www.instagram.com/s3strategies/" target="_blank" rel="noopener noreferrer">
                    <img src={"https://instagram-brand.com/wp-content/uploads/2016/11/Instagram_AppIcon_Aug2017.png?w=300"} alt="instagram" />
                    <h2>@s3strategies</h2>
                </a>
                <a href="https://twitter.com/s3strategies" target="_blank" rel="noopener noreferrer">
                    <img src={TwitterImage} alt="twitter" />
                    <h2>@s3strategies</h2>
                </a>
                <a href="https://www.facebook.com/s3strategiesPD/" target="_blank" rel="noopener noreferrer">
                    <img src={"https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512"} alt="facebook" />
                    <h2>@s3strategiesPD</h2>
                </a>
            </SocialMediaContainer>
        </Layout>
    )
}

export default ContactPage
